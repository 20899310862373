import React from 'react'
import s from './Text.module.scss'
import { Container } from '~views/shared/components'
import { ITextSection } from '~models'
import { createMarkup } from '~libs/utils'

interface Props {
  section: ITextSection
}
export class TextSection extends React.Component<Props> {
  render() {
    const { section } = this.props
    const { texts } = section
    const { wysiwygText } = texts[0]

    return (
      <section data-sectionname="Text">
        <Container noPadding fluid className={s.customContainer}>
          <div
            className={s.textWrapper}
            dangerouslySetInnerHTML={createMarkup(wysiwygText)}
          />
        </Container>
      </section>
    )
  }
}
